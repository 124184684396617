<template>
  <div id="quiz">
    <a id="quiz-top"></a>
    <div :class="['nav_locale', showLocaleSelector ? '' : 'is-not-visible']">
      <div :class="['locale-dropdown', showDropdown ? 'is-active' : '']">
        <div class="locale-dropdown__button button arrow-down"
             @click.prevent="toggleLocaleDropdown"
        >
          {{ toUpperCase($i18n.locale) }}
        </div>
        <div class="locale-dropdown__content">
          <template v-for="language in $i18n.availableLocales">
            <a v-if="language != $i18n.locale" :key="'language-' + language" @click.prevent="changeLanguage(language)">
              {{ toUpperCase(language) }}
            </a>
          </template>
        </div>
      </div>
    </div>
    <div
        :class="['nav_essentials', 'nav_item', screen === 'AllEssentials' ? 'is-active' : '', showAllEssentials ? '' : 'is-not-visible' ]"
        @click="changeToScreen('AllEssentials')">
      {{ $t('app.nav.all-essentials') }}
    </div>
    <div :class="['nav_about', 'nav_item', screen === 'About' ? 'is-active' : '', showAbout ? '' : 'is-not-visible' ]"
         @click="changeToScreen('About')">
      {{ $t('app.nav.about') }}
    </div>
    <div :class="['nav_reset', 'nav_item', showReset ? '' : 'is-not-visible' ]"
         @click="resetQuiz()">
      {{ $t('app.nav.reset') }}
    </div>
    <div :class="['nav_brand', showBrand ? '' : 'is-not-visible' ]">
      <img src="@/assets/images/arag-logo.svg">
    </div>
    <div class="screen-transition">
      <keep-alive>
        <transition name="screen-change" mode="out-in">
          <component :is="dynamicComponent"
                     @answered-question="onQuestionAnswer"
                     @close-screen="onCloseScreen"
                     @confetti="onConfetti"
                     @finished-quiz="onFinishQuiz"
                     @show-essential="onShowEssential"
                     @show-result="onShowResult"
                     @split-titles="onTitleSplit"
                     @start-quiz="onStartQuiz"
                     :activeEssential="activeEssential"
                     :debug="debug"
                     :essential="essential"
                     :key="componentKey"
                     :questions="questions"
                     :firstTimeResult="showingResultFirstTime"
          ></component>
        </transition>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import questions from './json/questions.json'

import About from "@/components/Screens/About";
import AllEssentials from "@/components/Screens/AllEssentials";
import Questions from "@/components/Quiz/Questions";
import Result from "@/components/Screens/Result";
import SingleEssential from "@/components/Screens/SingleEssential";
import Start from "@/components/Screens/Start";

import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText.js";

gsap.registerPlugin(SplitText);

export default {
  name: 'App',
  components: {
    About, AllEssentials, Questions, Result, SingleEssential, Start
  },
  computed: {
    dynamicComponent() {
      return this.screen;
    },
    showAbout() {
      let screens = ['About', 'AllEssentials', 'Result', 'SingleEssential', 'Start'];
      return screens.includes(this.screen);
    },
    showBrand() {
      let screens = ['Start'];
      return screens.includes(this.screen);
    },
    showAllEssentials() {
      let screens = ['About', 'AllEssentials', 'Result', 'SingleEssential'];
      return (this.essential != null && screens.includes(this.screen));
    },
    showLocaleSelector() {
      let screens = ['Login', 'Start'];
      return screens.includes(this.screen);
    },
    showReset() {
      return (this.essential !== null);
    }
  },
  data() {
    return {
      activeEssential: null,
      debug: false,
      componentKey: 'start',
      essential: null,
      previousScreen: 'Start',
      questions: questions,
      result: Array(6).fill(0),
      screen: 'Start',
      showingResultFirstTime: true,
      showDropdown: false
    }
  },
  methods: {

    changeLanguage(language) {
      this.$i18n.locale = language;
      this.showDropdown = false;
      this.splitTitles();
    },

    changeToScreen(newScreen) {
      if ((this.screen === newScreen) && this.screen === 'About') {
        this.changeToScreen(this.previousScreen);
      } else {
        this.previousScreen = this.screen;
        this.screen = newScreen;
        if (newScreen == 'SingleEssential') {
          this.componentKey = newScreen + '-' + this.activeEssential;
        } else {
          this.componentKey = newScreen
        }
      }
    },

    resetQuiz() {
      this.essential = null;
      this.showingResultFirstTime = true;
      this.result = Array(6).fill(0);
      this.changeToScreen('Start');
    },

    splitTitles() {
      const titles = document.querySelectorAll('.title.is-highlighted');
      titles.forEach(function (title) {
        title.innerHTML = title.textContent;
      });

      this.$nextTick(function () {
        new SplitText(".title.is-highlighted", {
          type: "lines, words",
          linesClass: "line",
          wordsClass: "word"
        });
      });
    },

    toggleLocaleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    toUpperCase(string) {
      return string.toUpperCase()
    },

    // Events
    onCloseScreen() {
      this.screen = this.previousScreen;
    },

    onConfetti() {
      this.showingResultFirstTime = false;
    },

    /**
     * Get the index of the maximum value of the quiz result
     * The maximum index is our result essential
     */
    onFinishQuiz() {
      let max = 0;
      let maxIndex = 1;
      // Essentials data start with index 0, so increase the array index
      this.result.forEach(function (value, index) {
        if (value > max) {
          max = value;
          maxIndex = (index + 1);
        }
      });
      this.essential = maxIndex;
      this.changeToScreen('Result');
    },

    /**
     * Add the score of an answer to the quiz result
     * @param answer Object with question- and answerIndex
     */
    onQuestionAnswer(answer) {
      let question = questions[answer.questionIndex];
      let answerScore = question['answers'][answer.answerIndex]['score'];

      const self = this;
      this.result.forEach(function (value, index) {
        self.result[index] += answerScore[index];
      });
    },

    /**
     * Show essential details
     * @param index
     */
    onShowEssential(index) {
      this.activeEssential = index;
      this.changeToScreen('SingleEssential');
    },

    onShowResult() {
      this.changeToScreen('Result');
    },

    onStartQuiz() {
      this.changeToScreen('Questions');
    },

    onTitleSplit() {
      this.splitTitles();
    }
  },
  mounted() {
    const self = this;
    window.addEventListener("resize", function () {
      self.splitTitles();
    });
  }
}
</script>

<style lang="scss">

@import './assets/scss/app.scss';

</style>

<template>
  <div class="screen start">
    <div class="image-fade is-hidden-mobile">
      <img :class="[(fadeImage === 1) ? 'is-active' : '']" src="@/assets/images/essentials/1.svg">
      <img :class="[(fadeImage === 2) ? 'is-active' : '']" src="@/assets/images/essentials/2.svg">
      <img :class="[(fadeImage === 3) ? 'is-active' : '']" src="@/assets/images/essentials/3.svg">
      <img :class="[(fadeImage === 4) ? 'is-active' : '']" src="@/assets/images/essentials/4.svg">
      <img :class="[(fadeImage === 5) ? 'is-active' : '']" src="@/assets/images/essentials/5.svg">
      <img :class="[(fadeImage === 6) ? 'is-active' : '']" src="@/assets/images/essentials/6.svg">
    </div>
    <div class="start__content">
      <h1 class="title is-1 is-highlighted" v-html="$t('screens.start.h-1')"/>
      <p class="intro">
        {{ $t('screens.start.p-1') }}
      </p>
      <button class="button arrow-right" type="submit" @click="startQuiz">
        {{ $t('screens.start.button') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  data() {
    return {
      fadeInterval: null,
      fadeImage: 1
    }
  },
  methods: {
    startQuiz() {
      this.$emit('start-quiz');
    }
  },
  mounted() {
    const self = this;
    this.$emit('split-titles');
    this.fadeInterval = setInterval(function(){
      self.fadeImage++;
      if (self.fadeImage === 7) {
        self.fadeImage = 1;
      }
    }, 4500);
  }
}
</script>

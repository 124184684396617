<template>
  <div class="screen questions">

    <div :class="[
          'questions__flow',
          'questions__flow--' + flow,
          'questions__flow--q-' + questionNumber,
          flowAnimation ? 'is-animated' : '',
          flowEnd ? 'flow-end' : '',
          flowImages ? 'show-images' : '',
          showingFlow ? 'show-flow' : '',
          ]">
      <div class="questions__flow__number">
        {{ questionNumber }}
      </div>
      <div class="questions__flow__essential questions__flow__essential--1">
        <img class="question__flow__shape" src="@/assets/images/essentials/flow-1.svg">
        <img class="question__flow__image" src="@/assets/images/essentials/1.svg">
      </div>
      <div class="questions__flow__essential questions__flow__essential--2">
        <img class="question__flow__shape" src="@/assets/images/essentials/flow-2.svg">
        <img class="question__flow__image" src="@/assets/images/essentials/2.svg">
      </div>
      <div class="questions__flow__essential questions__flow__essential--3">
        <img class="question__flow__shape" src="@/assets/images/essentials/flow-3.svg">
        <img class="question__flow__image" src="@/assets/images/essentials/3.svg">
      </div>
      <div class="questions__flow__essential questions__flow__essential--4">
        <img class="question__flow__shape" src="@/assets/images/essentials/flow-4.svg">
        <img class="question__flow__image" src="@/assets/images/essentials/4.svg">
      </div>
      <div class="questions__flow__essential questions__flow__essential--5">
        <img class="question__flow__shape" src="@/assets/images/essentials/flow-5.svg">
        <img class="question__flow__image" src="@/assets/images/essentials/5.svg">
      </div>
      <div class="questions__flow__essential questions__flow__essential--6">
        <img class="question__flow__shape" src="@/assets/images/essentials/flow-6.svg">
        <img class="question__flow__image" src="@/assets/images/essentials/6.svg">
      </div>
    </div>

    <div :class="['screen__background', showingQuestion ? 'is-shown' : '']">
      <template v-for="(question, index) in questions">
        <Question v-show="index == questionIndex" :key="'question-' +  index" :index="index" @selected-answer="onAnswerSelect"/>
      </template>
    </div>

    <div :class="['quiz-footer', showingUI ? 'is-shown': '']">
      <div class="quiz-progress">
        <div class="quiz-progress__bar">
          <div class="quiz-progress__bar__indicator"
               v-bind:style="{ width: ((questionIndex)/questions.length)*100 + '%'}">
          </div>
        </div>
        <div class="quiz-progress__caption">
          {{ questionNumber }} {{ $t('questions.of') }} {{ questions.length }}
        </div>
      </div>
      <button :class="['button', 'button--petrol', 'arrow-right', selectedAnswer ? 'is-active' : '']"
              @click="nextQuestion">
        <span v-if="questionNumber  == questions.length">
          {{ $t('questions.finish') }}
        </span>
        <span v-else>
          {{ $t('questions.continue') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import Question from "@/components/Quiz/Question";

export default {
  name: 'Questions',
  components: {
    Question
  },
  data() {
    return {
      flow: 0,
      flowEnd: false,
      flowFade: false,
      flowAnimation: false,
      flowImages: false,
      selectedAnswer: false,
      showingFlow: false,
      showingQuestion: false,
      showingUI: false,
      questionIndex: 0
    }
  },
  props: ['debug', 'questions'],
  computed: {
    questionNumber() {
      return this.questionIndex + 1;
    }
  },
  methods: {
    nextQuestion() {
      const self = this;
      if (self.selectedAnswer) {
        if (self.debug) {
          if ((self.questionIndex + 1) < self.questions.length) {
            self.$emit('answered-question', self.selectedAnswer);
            self.selectedAnswer = false;
            self.questionIndex++;
          } else {
            self.$emit('finished-quiz');
          }
        } else {
          // Fadeout question and ui
          self.flowImages = false;
          self.showingQuestion = false;
          self.showingUI = false;
          self.$emit('answered-question', self.selectedAnswer);

          setTimeout(function () {
            if ((self.questionIndex + 1) < self.questions.length) {
              setTimeout(function () {
                // Next question
                self.selectedAnswer = false;
                self.questionIndex++;

                setTimeout(function () {
                  // Show next flow
                  self.showFlow();
                }, 100);

              }, 500);
            } else {
              self.$emit('finished-quiz');
            }
          }, 500);
        }
      }
    },
    onAnswerSelect(answer) {
      this.selectedAnswer = answer;
    },
    showFlow() {
      const self = this;

      // Reset flow parameters
      self.flowAnimation = false;
      self.flowEnd = false;
      self.flowImages = false;

      // Fade In Flow
      self.flow++;
      if (self.flow === 4) self.flow = 1;
      self.showingFlow = true;

      // Show Flow Animation, after fade (flow fade: 500ms)
      setTimeout(function () {
        self.flowAnimation = true;

        // Show essential images (flow animation: 3000ms)
        // Flow end position
        setTimeout(function () {
          self.flowEnd = true;
          self.flowImages = true;

          // Break (show final flow state)
          setTimeout(function () {
            self.showingFlow = false;

            // Show Question, after flow fade out
            setTimeout(function () {
              self.$emit('split-titles');
              self.showingUI = true;
              self.showingQuestion = true;
            }, 500)
          }, 500);
        }, 2000)
      }, 1000)
    }
  },
  mounted() {
    const self = this;
    setTimeout(function () {
      self.showFlow();
    }, 100);
  }
}
</script>

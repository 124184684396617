<template>
  <div class="screen essential">
    <div class="screen__background">
      <div :class="['essential-details', 'essential-details--top', 'essential--' + activeEssential]">
        <div class="essential__image"></div>
      </div>
      <h1 class="title is-2 is-highlighted" v-html="$t('essentials.' + activeEssential + '.single.headline')"></h1>

      <p>
        <strong>{{ $t('essentials.single.title-1') }}</strong><br>
        {{ $t('essentials.' + activeEssential + '.single.text-1') }}
      </p>

      <p>
        <strong>{{ $t('essentials.single.title-2') }}</strong><br>
        {{ $t('essentials.' + activeEssential + '.single.text-2') }}
      </p>

      <div class="essential-details__wrapper">
        <div :class="['essential-details', 'essential-details--partner', 'essential--' + partnerEssential]">
          <div class="essential__image"></div>
        </div>
        <p>
          <strong>{{ $t('essentials.single.title-3') }}</strong><br>
          {{ $t('essentials.' + activeEssential + '.single.text-3-a') }}
          <a :class="['link', 'link--essential-' + partnerEssential]" href="#" @click="showEssential(partnerEssential)">{{
              $t('essentials.' + activeEssential + '.single.text-3-link')
            }}</a>{{ $t('essentials.' + activeEssential + '.single.text-3-b') }}
        </p>
      </div>

      <div class="essential-details__wrapper">
        <div :class="['essential-details', 'essential-details--partner', 'essential--' + partnerEssential2]">
          <div class="essential__image"></div>
        </div>
        <p>
          {{ $t('essentials.' + activeEssential + '.result.text-4-a') }}
          <a :class="['link', 'link--essential-' + partnerEssential2]" href="#"
             @click="showEssential(partnerEssential2)">{{
              $t('essentials.' + activeEssential + '.result.text-4-link')
            }}</a>{{ $t('essentials.' + activeEssential + '.result.text-4-b') }}
        </p>
      </div>

      <p>
        <strong>{{ $t('essentials.single.title-5') }}</strong><br>
        {{ $t('essentials.' + activeEssential + '.single.text-5') }}
      </p>
    </div>
    <div class="screen__top-link">
      <a href="#quiz-top" @click.prevent="scrollToTop">
        <img src="@/assets/images/top-link.svg" width="44" height="44">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleEssential',
  props: ['activeEssential'],
  computed: {
    partnerEssential() {
      let partnerEssential = 2;
      switch (this.activeEssential) {
        case 1:
          partnerEssential = 4;
          break;
        case 2:
          partnerEssential = 6;
          break;
        case 3:
          partnerEssential = 2;
          break;
        case 4:
          partnerEssential = 5;
          break;
        case 5:
          partnerEssential = 3;
          break;
        case 6:
          partnerEssential = 4;
          break;
      }
      return partnerEssential;
    },
    partnerEssential2() {
      let partnerEssential = 1;
      switch (this.activeEssential) {
        case 1:
          partnerEssential = 2;
          break;
        case 2:
          partnerEssential = 1;
          break;
        case 3:
          partnerEssential = 6;
          break;
        case 4:
          partnerEssential = 3;
          break;
        case 5:
          partnerEssential = 1;
          break;
        case 6:
          partnerEssential = 5;
          break;
      }
      return partnerEssential;
    }
  },
  methods: {
    scrollToTop() {
      document.querySelector('#quiz-top').scrollIntoView({
        behavior: "smooth"
      });
    },
    showEssential(index) {
      document.querySelector('#quiz').scrollTo({top: 0, behavior: 'smooth'});
      this.$emit('show-essential', index);
    }
  },
  mounted() {
    this.$emit('split-titles');
  },
  updated() {
    this.$emit('split-titles');
  }

}
</script>

<template>
    <div class="question">
      <h2 class="title is-3 is-highlighted">
        {{ $t('questions.' + (index + 1) + '.question') }}
      </h2>
      <ul class="answers">
        <li v-for="(n, i) in 4" :key="'answer-'+ i" @click="selectAnswer(index, i)"
            :class="[i === selectedAnswer ? 'is-active' : '']">
          <div class="answers__dot"></div>
          <span v-html="$t('questions.' + (index + 1) + '.answers.' + n)"></span>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'Question',
  props: ['index'],
  data() {
    return {
      selectedAnswer: -1
    }
  },
  methods: {
    selectAnswer(questionIndex, answerIndex) {
      this.selectedAnswer = answerIndex;
      this.$emit('selected-answer', {
        'questionIndex': questionIndex,
        'answerIndex': answerIndex
      });
    }
  },
}
</script>

import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
Vue.use(VueI18n);

import LocaleDE from './locales/de.json';
import LocaleEN from './locales/en.json';
import LocaleFR from './locales/fr.json';
import LocaleIT from './locales/it.json';
import LocaleES from './locales/es.json';
import LocaleNL from './locales/nl.json';
import LocaleFL from './locales/fl.json';

// Retrieve locale setting from HTML file
let appElement = document.getElementById('app');
let locale = appElement.getAttribute('data-locale')



const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: ['en'],
    messages: {
        de: LocaleDE,
        en: LocaleEN,
        fl: LocaleFL,
        fr: LocaleFR,
        it: LocaleIT,
        es: LocaleES,
        nl: LocaleNL,
    }
});

Vue.config.productionTip = false

new Vue({
    i18n,
    render: h => h(App),
}).$mount('#app')


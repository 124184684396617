<template>
  <div class="screen all-essentials">
    <a v-for="i in 6" :key="'essential-' + i"
       :class="['essential-details', 'essential--' + i]"
       @click="showEssential(i)">
      <h2 class="title is-4 is-highlighted" v-html="$t('essentials.' + i + '.title')"></h2>
      <div class="essential__image"></div>
      <div v-if="essential === i" class="essential__badge">
        {{ $t('essentials.your-result') }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'AllEssentials',
  props: ['essential'],
  methods: {
    closeScreen() {
      this.$emit('close-screen');
    },
    setEssentialsHeight() {
      let essentials = document.querySelectorAll('.essential-details');
      essentials.forEach(function (essential) {
        let width = essential.offsetWidth;
        width = essential.clientWidth;
        essential.style.height = width + 'px';
      });
    },
    showEssential(index) {
      if (this.essential === index) {
        this.$emit('show-result', index);
      } else {
        this.$emit('show-essential', index);
      }
    }
  },
  mounted() {
    let self = this;
    this.$emit('split-titles');
    this.setEssentialsHeight();

    window.addEventListener('resize', function(){
      self.setEssentialsHeight();
    });

  }
}
</script>

<template>
  <div class="screen about">
    <div class="screen__background">
      <div class="screen__close" @click="closeScreen"></div>
      <div class="content">
        <h1 class="title is-3 is-highlighted">{{ $t('screens.about.h-1') }}</h1>
        <p>{{ $t('screens.about.p-1') }}</p>
        <p>
          <strong>{{ $t('screens.about.h-2') }}</strong><br>
          {{ $t('screens.about.p-2') }}
        </p>
        <p>
          <strong>{{ $t('screens.about.h-3') }}</strong><br>
          {{ $t('screens.about.p-3') }}
        </p>
        <p>{{ $t('screens.about.p-4') }}</p>
        <p>{{ $t('screens.about.p-5') }}</p>
      </div>
    </div>
    <div class="screen__top-link">
      <a href="#quiz-top" @click.prevent="scrollToTop">
        <img src="@/assets/images/top-link.svg" width="44" height="44">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  methods: {
    closeScreen() {
      this.$emit('close-screen');
    },
    scrollToTop() {
      document.querySelector('#quiz-top').scrollIntoView({
        behavior: "smooth"
      });
    }
  },
  mounted() {
    this.$emit('split-titles');
  }
}
</script>
